import λ from '~/utils/invoker';
import FormMixin from '~/mixins/form';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import TrainerFields from '~/trainers/trainer-fields.vue';
import PermissionMixin from '~/mixins/permission';
import { displayName } from '#/trainer';
import { ValidationObserver } from 'vee-validate';
import { mdiContentSaveOutline } from '@mdi/js';
const trainersλ = λ.namespaced('trainers');
export default {
    name: 'trainers-form',
    mixins: [FormMixin('trainers'), PermissionMixin],
    components: {
        ValidationObserver,
        GoBackWrapper,
        TrainerFields,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            mdiContentSaveOutline,
        };
    },
    async created() {
        await this.prepareForm(this.id);
    },
    computed: {
        item: trainersλ.state('editing'),
        canList: λ.self('canListTrainers'),
        canSave() {
            return this.canUpdateTrainers;
        },
    },
    methods: {
        displayName,
    },
};
