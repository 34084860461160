import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DateTimeField from '~/components/date-time-field.vue';
import ItemSearchSelector from '~/components/item-search-selector.vue';
export default {
    name: 'certificate-fields',
    components: {
        ValidationObserver,
        ValidationProvider,
        DateTimeField,
        ItemSearchSelector
    },
    model: {
        prop: 'certificate',
    },
    props: {
        certificate: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
};
