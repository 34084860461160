import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CertificateCard from '~/certificates/certificate-card.vue';
import { getId } from 'shared/src/object-id';
import { createCertificate } from '#/trainer';
import { mdiEmail } from '@mdi/js';
export default {
    name: 'trainer-fields',
    components: {
        ValidationObserver,
        ValidationProvider,
        CertificateCard,
    },
    model: {
        prop: 'trainer',
    },
    props: {
        trainer: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            mdiEmail,
        };
    },
    methods: {
        addCertificate() {
            const certificate = createCertificate();
            this.trainer.certificates.push(certificate);
        },
        removeCertificate(certificate) {
            const toDeleteId = getId(certificate);
            this.trainer.certificates.splice(this.trainer.certificates.findIndex(item => getId(item) === toDeleteId), 1);
        },
    },
};
