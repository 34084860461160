import { format, isValid, parse } from 'date-fns';
export default {
    name: 'date-time-picker',
    props: {
        value: {
            type: Date,
        },
        allowedDates: Function,
        useTime: {
            type: Boolean,
            default: true,
        },
        actionButtons: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dateValue: null,
            timeValue: null,
        };
    },
    mounted() {
        this.$refs['time-picker'].selectingHour = true;
    },
    watch: {
        dateValue() {
            if (this.dateIsOk && !this.actionButtons) {
                this.$emit('input', this.parsedDate);
            }
        },
        timeValue() {
            if (this.dateIsOk && !this.actionButtons) {
                this.$emit('input', this.parsedDate);
            }
        },
        value: {
            handler(value) {
                this.dateValue = null;
                this.timeValue = '00:00';
                if (isValid(value)) {
                    this.dateValue = format(value, 'yyyy-LL-dd');
                    this.timeValue = format(value, 'HH:mm').substr(0, 5);
                }
            },
            immediate: true,
        },
    },
    computed: {
        parsedDate() {
            return parse(`${this.dateValue} ${this.timeValue}`, 'yyyy-LL-dd HH:mm', new Date());
        },
        dateIsOk() {
            return isValid(this.parsedDate);
        }
    },
    methods: {
        confirm() {
            if (this.dateIsOk) {
                this.$emit('close');
                this.$emit('input', this.parsedDate);
            }
        }
    },
};
