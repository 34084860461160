var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.trainer)?_c('div',{staticClass:"trainer-fields"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t('General'))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"firstName",attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"firstName","label":_vm.$t('First name'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.firstName),callback:function ($$v) {_vm.$set(_vm.trainer, "firstName", $$v)},expression:"trainer.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"lastName",attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"lastName","label":_vm.$t('Last name'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.lastName),callback:function ($$v) {_vm.$set(_vm.trainer, "lastName", $$v)},expression:"trainer.lastName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"email",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","label":_vm.$t('E-mail'),"prepend-inner-icon":_vm.mdiEmail,"error-messages":errors,"disabled":_vm.readOnly,"autocapitalize":"none","autocomplete":"off","outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.email),callback:function ($$v) {_vm.$set(_vm.trainer, "email", $$v)},expression:"trainer.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"status",attrs:{"name":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"status","label":_vm.$t('Status'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.status),callback:function ($$v) {_vm.$set(_vm.trainer, "status", $$v)},expression:"trainer.status"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"employer",attrs:{"name":"employer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"employer","label":_vm.$t('Employer'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.employer),callback:function ($$v) {_vm.$set(_vm.trainer, "employer", $$v)},expression:"trainer.employer"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"isStateEmployee",attrs:{"name":"isStateEmployee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"name":"isStateEmployee","label":_vm.$t('Is State Employee'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.isStateEmployee),callback:function ($$v) {_vm.$set(_vm.trainer, "isStateEmployee", $$v)},expression:"trainer.isStateEmployee"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"languages",attrs:{"name":"languages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"languages","label":_vm.$t('Languages'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.trainer.languages),callback:function ($$v) {_vm.$set(_vm.trainer, "languages", $$v)},expression:"trainer.languages"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{ref:"diploma",attrs:{"name":"diploma"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('Diplomas'),"error-messages":errors,"disabled":_vm.readOnly,"hide-details":"","outlined":""},on:{"input":validate},model:{value:(_vm.trainer.diploma),callback:function ($$v) {_vm.$set(_vm.trainer, "diploma", $$v)},expression:"trainer.diploma"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h3',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t('Certificates'))+" ")]),_c('v-row',[_vm._l((_vm.trainer.certificates),function(certificate,index){return _c('v-col',{key:certificate._id,attrs:{"cols":"12"}},[_c('certificate-card',{attrs:{"certificate":certificate,"label":("#" + (index + 1)),"read-only":_vm.readOnly},on:{"certificate:remove":function($event){return _vm.removeCertificate(certificate)}}})],1)}),(_vm.trainer.certificates.length < 2)?_c('v-col',{attrs:{"cols":"12"}},[_c('certificate-card',{attrs:{"certificate":undefined,"label":("#" + (_vm.trainer.certificates.length + 1)),"read-only":_vm.readOnly},on:{"certificate:add":_vm.addCertificate}})],1):_vm._e()],2)],1)],1)]}}],null,false,2549698095)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }