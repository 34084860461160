import CertificateFields from '~/certificates/certificate-fields.vue';
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js';
export default {
    name: 'certificate-card',
    components: { CertificateFields },
    props: {
        certificate: Object,
        label: String,
        flat: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mdiPlusCircle,
            mdiMinusCircle,
            localCertificate: undefined,
        };
    },
    created() {
        this.localCertificate = this.certificate;
    },
    watch: {
        localCertificate: {
            handler(localCertificate) {
                this.$emit('certificate:changed', localCertificate);
            },
            deep: true,
        },
    },
    methods: {
        addCertificate() {
            this.$emit('certificate:add');
        },
        removeCertificate() {
            this.localCertificate = undefined;
            this.$emit('certificate:remove');
        },
    },
};
