import { toLocalDate, toLocalDateTime } from '#/date-fns-utils';
import DateTimePicker from '~/components/date-time-picker.vue';
import { isValid, parseISO } from 'date-fns';
export default {
    name: 'date-time-field',
    components: { DateTimePicker },
    props: {
        value: {
            type: [String, Date]
        },
        label: String,
        allowedDates: Function,
        useTime: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: Array,
            default() { return []; },
        },
    },
    data() {
        return {
            active: false,
        };
    },
    computed: {
        date() {
            if (typeof this.value === 'string') {
                return parseISO(this.value);
            }
            return this.value;
        },
        displayDate() {
            if (!isValid(this.date)) {
                return '';
            }
            return this.useTime ? toLocalDateTime(this.date) : toLocalDate(this.date);
        },
    },
    methods: {
        clearDate() {
            this.$emit('input', null);
        },
        setDate(date) {
            this.$emit('input', date.toISOString());
        },
    },
};
