var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.certificate)?_c('div',{staticClass:"certificate-fields"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"type",attrs:{"name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"type","label":_vm.$t('Certificate Type'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.certificate.type),callback:function ($$v) {_vm.$set(_vm.certificate, "type", $$v)},expression:"certificate.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"specification",attrs:{"name":"specification"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"specification","label":_vm.$t('Specification'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.certificate.specification),callback:function ($$v) {_vm.$set(_vm.certificate, "specification", $$v)},expression:"certificate.specification"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"activityType",attrs:{"name":"activityType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"activityType","label":_vm.$t('Activity Type'),"error-messages":errors,"disabled":_vm.readOnly,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.certificate.activityType),callback:function ($$v) {_vm.$set(_vm.certificate, "activityType", $$v)},expression:"certificate.activityType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"processingDate",attrs:{"name":"processingDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-field',{attrs:{"label":_vm.$t('Processing Date'),"error-messages":errors,"disabled":_vm.readOnly,"useTime":false,"outlined":"","dense":""},on:{"input":validate},model:{value:(_vm.certificate.processingDate),callback:function ($$v) {_vm.$set(_vm.certificate, "processingDate", $$v)},expression:"certificate.processingDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{ref:"agency",attrs:{"name":"agency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('item-search-selector',{attrs:{"name":"agency","resources":['agencies'],"label":_vm.$tc('agencies.title', 1),"item-text":function (item) { return item.name; },"error-messages":errors,"disabled":_vm.readOnly,"hide-details":"","no-filter":"","clearable":"","outlined":"","dense":""},on:{"input":function($event){return validate()}},model:{value:(_vm.certificate.agency),callback:function ($$v) {_vm.$set(_vm.certificate, "agency", $$v)},expression:"certificate.agency"}})]}}],null,true)})],1)],1)]}}],null,false,2439728460)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }